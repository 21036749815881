import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledIdentity, Item, Content, Name, Prop, Key, Value, CTASection } from './Identity.Styled'
import useFunctional from '../../../../../interface/useFunctional'
import useCRUD from '../../../../../interface/useCRUD'
import routes from '../../../../../redux/routes'
import { setLocal } from '../../../../../redux/action/local'
import { useEffect } from 'react'
import { list } from '../../../../../redux/action/crud'
import { Button } from '@think-internet/ui-components'

const List = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const identities = useSelector((s) => s[props.IDENTITY])
  const removeIdentity = useCRUD(props.IDENTITY).remove
  const getDomainIdentityStatus = useFunctional(routes.get_identity_status)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!identities) {
      dispatch(list(props.IDENTITY))
    }
  }, [identities, dispatch])

  const getStatus = async (uuid) => {
    const response = await getDomainIdentityStatus({ uuid })
    if (!!response) {
      dispatch(
        setLocal(
          props.IDENTITY,
          identities.map((i) => (i.uuid === uuid ? response : i)),
        ),
      )
    }
  }

  const remove = async (uuid) => {
    const response = await removeIdentity({ uuid })
    if (!!response) {
      dispatch(
        setLocal(
          props.IDENTITY,
          identities.filter((i) => i.uuid !== uuid),
        ),
      )
    }
  }

  if (!Array.isArray(identities)) return <></>
  return (
    <StyledIdentity>
      {identities.map((i) => (
        <Item key={i.uuid}>
          <Content>
            <Name>{i.identity}</Name>
            {i.status !== 'Success' && (
              <Prop>
                <Key>{translation.dashboard.sharedComponents.list.identity.item.status}</Key>
                <Value>{i.status}</Value>
              </Prop>
            )}
            {i.status !== 'Success' && i.token && (
              <Prop>
                <Key>{translation.dashboard.sharedComponents.list.identity.item.token}</Key>
                <Value>{i.token}</Value>
              </Prop>
            )}
          </Content>
          <CTASection>
            {i.status !== 'Success' && (
              <Button text={translation.dashboard.sharedComponents.list.identity.item.refresh} onClick={() => getStatus(i.uuid)} />
            )}
            <Button
              className="danger"
              text={translation.dashboard.sharedComponents.list.identity.item.remove}
              variant="danger"
              onClick={() => remove(i.uuid)}
            />
          </CTASection>
        </Item>
      ))}
    </StyledIdentity>
  )
}

export default List
