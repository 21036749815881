import styled from 'styled-components'

export const StyledAccount = styled.div`
  padding: 150px 0;
`

export const Boxes = styled.div`
  margin-top: 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;
`
