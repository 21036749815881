import styled from 'styled-components'

export const StyledBox = styled.div`
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: ${({ theme }) => theme.border.radius};
`

export const Title = styled.div`
  cursor: pointer;
  padding: 10px;
  font-family: 'bold';
`

export const Body = styled.div`
  padding: 10px;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
`
