import { Button, Container } from '@think-internet/ui-components'
import { useParams } from 'react-router-dom'
import props from '../../../../redux/props'
import Layout from '../../../Layout/Layout'
import AddContacts from './AddContacts/AddContacts'
import { StyledSandbox, Headline, Hint } from './Sandbox.Styled'
import { contactModes, levels } from '../../../../enum'
import ContactList from '../../SharedComponents/List/Contact/Contact'
import { useSelector } from 'react-redux'
import useFunctional from '../../../../interface/useFunctional'
import routes from '../../../../redux/routes'
import toast, { TYPE } from '../../../../toast'
import SetMailHeader from './SetMailHeader/SetMailHeader'

const Sandbox = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const fromSandboxToProduction = useFunctional(routes.move_contacts_to_production)

  const moveToProduction = async () => {
    const response = await fromSandboxToProduction({ contactListUUID: uuid })
    if (response) {
      toast(TYPE.SUCCESS, translation.dashboard.user.sandbox.success.moveToProduction)
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.sandbox.error.moveToProduction)
    }
  }

  return (
    <Layout requiredLevel={[levels.MANAGER, levels.USER]}>
      <StyledSandbox>
        <Container>
          <Headline>{translation.dashboard.user.sandbox.headline}</Headline>
          <Hint>{translation.dashboard.user.sandbox.hint}</Hint>
          <AddContacts contactListUUID={uuid} />
          <SetMailHeader />
          <Button onClick={moveToProduction} text={translation.dashboard.user.listView.moveToProduction} />
          <ContactList contactListUUID={uuid} mode={contactModes.SANDBOX} />
        </Container>
      </StyledSandbox>
    </Layout>
  )
}

export default Sandbox
