import styled from 'styled-components'

export const StyledTemplate = styled.div``
export const Split = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column-reverse;
  }
`
export const InputWrapper = styled.div`
  width: 70%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
export const VariablesWrapper = styled.div`
  width: 30%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
