import { Route, Routes, Navigate } from 'react-router-dom'
import Account from './Account/Account'
import Users from './Users/Users'
import Lists from './ContactList/ContactList'
import ListView from './ContactList/ListView/ListView'
import Sandbox from './ContactList/Sandbox/Sandbox'
import Newsletters from './Newsletters/Newsletters'
import NewsletterView from './Newsletters/NewsletterView/NewsletterView'

const Dashboard = () => {
  return (
    <Routes>
      <Route path={'account'} element={<Account />} />
      <Route path={'users'} element={<Users />} />
      <Route path={'lists'} element={<Lists />} />
      <Route path={'list/:uuid'} element={<ListView />} />
      <Route path={'sandbox/:uuid'} element={<Sandbox />} />
      <Route path={'newsletters'} element={<Newsletters />} />
      <Route path={'newsletter/:uuid'} element={<NewsletterView />} />
      <Route path={'*'} element={<Navigate replace to="/newsletter" />} />
    </Routes>
  )
}

export default Dashboard
