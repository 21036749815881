import styled from 'styled-components'

export const StyledContactList = styled.div``

export const Headline = styled.div`
  font-family: 'bold';
`

export const Items = styled.div``

export const Item = styled.div`
  padding: 10px 0px;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
  text-decoration: none;
  ${({ mode, theme }) =>
    mode === 'SELECTION' &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.color.background.lightgray};
    }
  `}
  ${({ selected, theme }) =>
    selected &&
    `
  background-color: ${theme.color.background.lightgray};
  `}
  &:first-child {
    border-top: none;
  }
`
