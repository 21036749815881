const props = {
  TOKEN: 'TOKEN',
  ACCOUNT: 'ACCOUNT',
  CURRENTLANG: 'CURRENTLANG',
  TRANSLATION: 'TRANSLATION',

  USER: 'USER',
  ORGANIZATION: 'ORGANIZATION',
  CONTACT_LIST: 'CONTACT_LIST',
  CONTACT_LIST_HEADER: 'CONTACT_LIST_HEADER',
  IDENTITY: 'IDENTITY',
  CONTACT: 'CONTACT',
  SENDER: 'SENDER',
  NEWSLETTER: 'NEWSLETTER',
  NEWSLETTER_PROTOCOL: 'NEWSLETTER_PROTOCOL',
  NEWSLETTER_CONTACT_PROTOCOL: 'NEWSLETTER_CONTACT_PROTOCOL',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  BLACKLIST: 'BLACKLIST',
}

export default props
