import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './global.scss'
import Login from './components/Login/Login'
import SignUp from './components/SignUp/SignUp'
import ActivateAccount from './components/ActivateAccount/ActivateAccount'
import Unsubscribe from './components/Unsubscribe/Unsubscribe'
import Dashboard from './components/Dashboard/Dashboard'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route exact path={'login'} element={<Login />} />
            <Route exact path={'signup'} element={<SignUp />} />
            <Route exact path={'signup/:accountUUID'} element={<ActivateAccount />} />
            <Route exact path={'unsubscribe/:contactUUID'} element={<Unsubscribe />} />
            <Route path={'dashboard/*'} element={<Dashboard />} />
            <Route path={'*'} element={<Navigate replace to="/login" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
