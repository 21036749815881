import { getUUID } from '../../../../../../utility'
import { Prop, StyledItem } from './Item.Styled'

const Item = ({ headers, contact, index }) => {
  if (!contact.data) return null

  return (
    <StyledItem>
      <Prop key={getUUID()}>{index + 1}</Prop>
      {headers.map((h) => (
        <Prop key={h.label}>{contact.data[h.label]}</Prop>
      ))}
    </StyledItem>
  )
}

export default Item
