import styled from 'styled-components'

export const StyledUnsubscribe = styled.div`
  padding: 150px 0;
`

export const Message = styled.div`
  margin: 25px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
`
