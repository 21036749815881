const enumCollection = {
  operations: {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  },
  location_availability: {
    AVAILABLE: 'AVAILABLE',
    LIMITED: 'LIMITED',
    UNAVAILABLE: 'UNAVAILABLE',
  },
  levels: {
    ADMIN: 'ADMIN',
    MANAGER: 'MANAGER',
    USER: 'USER',
  },
  contactModes: {
    SANDBOX: 'SANDBOX',
    PRODUCTION: 'PRODUCTION',
  },
}

module.exports = enumCollection
