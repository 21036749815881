import styled from 'styled-components'

export const StyledAddContacts = styled.div`
  margin-top: 15px;
`

export const Processing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 25px 0px;
  flex-direction: column;
`

export const Loading = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.font.primary};
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const Label = styled.div`
  font-family: 'bold';
  margin-top: 10px;
`
