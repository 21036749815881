import { Input, Button } from '@think-internet/ui-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { create } from '../../../../interface/crud'
import { list as listCrud } from '../../../../redux/action/crud'
import props from '../../../../redux/props'
import toast, { TYPE } from '../../../../toast'
import { CustomForm, Headline, StyledCreate } from './Create.Styled'

const Create = () => {
  const [payload, setPayload] = useState({})
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()

  const updateProp = (prop) => (value) => setPayload({ ...payload, [prop]: value })

  const submit = async () => {
    const list = await create(token, props.CONTACT_LIST, payload)
    if (list) {
      toast(TYPE.SUCCESS, translation.dashboard.user.contactLists.create.success.submit)
      setPayload({})
      dispatch(listCrud(props.CONTACT_LIST))
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.contactLists.create.error.submit)
    }
  }

  return (
    <StyledCreate>
      <Headline>{translation.dashboard.user.contactLists.create.headline}</Headline>
      <CustomForm onSubmit={submit}>
        <Input value={payload.name} onChange={updateProp('name')} placeholder={translation.generic.name} required />
        <Button type="submit" text={translation.generic.save} />
      </CustomForm>
    </StyledCreate>
  )
}

export default Create
