import { StyledAccount, Boxes } from './Account.Styled'
import { Container } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { levels } from '../../../enum'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Headline from '../../SubComponents/Headline/Headline'
import Box from '../../SubComponents/Box/Box'
import Identities from './Identities/Identities'
import Senders from './Senders/Senders'

const Account = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={[levels.MANAGER]}>
      <StyledAccount>
        <Container>
          <Headline text={translation.dashboard.user.account.headline} align="center" />
          <Boxes>
            <Box title={translation.dashboard.user.account.identities.title}>
              <Identities />
            </Box>
            <Box title={translation.dashboard.user.account.senders.title}>
              <Senders />
            </Box>
          </Boxes>
        </Container>
      </StyledAccount>
    </Layout>
  )
}

export default Account
