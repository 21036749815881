import styled from 'styled-components'

export const StyledSender = styled.div``

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
  ${({ mode, theme }) =>
    mode === 'SELECTION' &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.color.background.lightgray};
    }
  `}
  ${({ selected, theme }) =>
    selected &&
    `
  background-color: ${theme.color.background.lightgray};
  `}
  &:first-child {
    border-top: none;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Name = styled.div`
  font-family: 'bold';
`
export const CTASection = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`
