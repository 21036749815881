import { Button, Container, Input } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { levels } from '../../../../enum'
import toast, { TYPE } from '../../../../toast'
import useCRUD from '../../../../interface/useCRUD'
import { list } from '../../../../redux/action/crud'
import props from '../../../../redux/props'
import Layout from '../../../Layout/Layout'
import Box from '../../../SubComponents/Box/Box'
import Headline from '../../../SubComponents/Headline/Headline'
import { StyledNewsletterView, Boxes, CTAWrapper } from './NewsletterView.Styled'
import Sender, { MODES as SENDERMODES } from '../../SharedComponents/List/Sender/Sender'
import ContactList, { MODES as CONTACTLISTMODES } from '../../SharedComponents/List/ContactList/ContactList'
import Template from './Template/Template'
import routes from '../../../../redux/routes'
import useFunctional from '../../../../interface/useFunctional'

const NewsletterView = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const get = useCRUD(props.NEWSLETTER).get
  const update = useCRUD(props.NEWSLETTER).update
  const sendTestNewsletter = useFunctional(routes.send_test_newsletter)
  const sendProductionNewsletter = useFunctional(routes.send_production_newsletter)
  const [newsletter, setNewsletter] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchNewsletter = async () => {
      const response = await get({ uuid })
      if (response) {
        setNewsletter(response)
        setNewsletter(response)
        dispatch(list(props.SENDER))
        dispatch(list(props.CONTACT_LIST))
      } else {
        toast(TYPE.ERROR, translation.dashboard.user.newsletterView.error.fetching)
      }
    }
    if (token && !newsletter) {
      fetchNewsletter()
    }
  }, [uuid, get, newsletter, token, dispatch, translation])

  const getSenderBoxTitle = () => {
    return translation.dashboard.user.newsletterView.sender.label
  }
  const getContactListBoxTitle = () => {
    return translation.dashboard.user.newsletterView.contactList.label
  }

  const setProp = (prop) => (value) => setNewsletter({ ...newsletter, [prop]: value })

  const submit = async (silence = false) => {
    await update(
      { uuid: newsletter.uuid, ...newsletter },
      translation.dashboard.user.newsletterView.error.save,
      silence ? null : translation.dashboard.user.newsletterView.success.save,
    )
  }

  const sendTest = async () => {
    await submit(true)
    await sendTestNewsletter(
      { newsletterUUID: uuid, receiverInput: 'tim@think-internet.de' },
      translation.dashboard.user.newsletterView.error.sendTest,
      translation.dashboard.user.newsletterView.success.sendTest,
    )
  }
  const sendProduction = async () => {
    await submit(true)
    await sendProductionNewsletter(
      { newsletterUUID: uuid },
      translation.dashboard.user.newsletterView.error.sendProduction,
      translation.dashboard.user.newsletterView.success.sendProduction,
    )
  }

  return (
    <Layout requiredLevel={[levels.MANAGER, levels.USER]}>
      {newsletter && (
        <StyledNewsletterView>
          <Headline text={newsletter.name} align="center" />
          <Container>
            <Boxes>
              <Box title={getSenderBoxTitle()}>
                <Sender mode={SENDERMODES.SELECTION} selectedSenderUUID={newsletter.senderUUID} clickedHandler={setProp('senderUUID')} />
              </Box>
              <Box title={getContactListBoxTitle()}>
                <ContactList
                  mode={CONTACTLISTMODES.SELECTION}
                  selectedContactListUUID={newsletter.contactListUUID}
                  clickedHandler={setProp('contactListUUID')}
                />
              </Box>
            </Boxes>
            <Input
              value={newsletter.subject}
              onChange={setProp('subject')}
              title={translation.dashboard.user.newsletterView.subject.label}
              placeholder={translation.dashboard.user.newsletterView.subject.placeholder}
            />
            <Template value={newsletter.template} contactListUUID={newsletter.contactListUUID} changeHander={setProp('template')} />
            <CTAWrapper>
              <Button text={translation.generic.save} onClick={() => submit(false)} />
              <Button text={translation.dashboard.user.newsletterView.sendTestCTA} onClick={sendTest} />
              <Button text={translation.dashboard.user.newsletterView.sendProductionCTA} onClick={sendProduction} />
            </CTAWrapper>
          </Container>
        </StyledNewsletterView>
      )}
    </Layout>
  )
}

export default NewsletterView
