import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import { levels } from '../../../enum'
import props from '../../../redux/props'
import Layout from '../../Layout/Layout'
import Headline from '../../SubComponents/Headline/Headline'
import Create from './Create/Create'
import List from '../SharedComponents/List/Newsletter/Newsletter'
import { StyledNewsletters } from './Newsletters.Styled'

const Newsletters = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout requiredLevel={[levels.MANAGER, levels.USER]}>
      <StyledNewsletters>
        <Headline text={translation.dashboard.user.newsletters.headline} align="center" />
        <Container>
          <Create />
          <List />
        </Container>
      </StyledNewsletters>
    </Layout>
  )
}

export default Newsletters
