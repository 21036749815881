import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { StyledContact, Headline, Headers, HeaderItem, ListWrapper, List, Contacts } from './Contact.Styled'
import LoadMore from './LoadMore/LoadMore'
import props from '../../../../../redux/props'
import { useState } from 'react'
import useCRUD from '../../../../../interface/useCRUD'
import { getUUID } from '../../../../../utility'
import Item from './Item/Item'

const Contact = ({ contactListUUID, mode }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [contacts, setContacts] = useState(null)
  const [list, setList] = useState(null)
  const listContact = useCRUD(props.CONTACT).list
  const getList = useCRUD(props.CONTACT_LIST).get

  const getNextPageIndex = useCallback(() => {
    const pageSize = 50
    let pageIndex = 0
    if (Array.isArray(contacts)) {
      if (contacts.length === 0 || contacts.length % pageSize !== 0) return false
      pageIndex = contacts.length / pageSize
    }
    return pageIndex
  }, [contacts])

  const fetchContacts = useCallback(async () => {
    const response = await listContact(
      { contactListUUID, mode },
      translation.dashboard.sharedComponents.contactList.error.fetching,
      null,
      getNextPageIndex(),
    )
    if (response) {
      if (Array.isArray(contacts)) {
        setContacts([...contacts, ...response])
      } else {
        setContacts(response)
      }
    }
  }, [contactListUUID, mode, translation, listContact, contacts, setContacts, getNextPageIndex])

  const fetchList = useCallback(async () => {
    const response = await getList({ uuid: contactListUUID }, translation.dashboard.sharedComponents.contactList.error.fetching, null)
    if (response) {
      setList(response)
    }
  }, [contactListUUID, translation, getList])

  useEffect(() => {
    if (token) {
      if (!list) {
        fetchList()
      }
      if (!contacts) {
        fetchContacts()
      }
    }
  }, [contacts, token, fetchContacts, list, fetchList])

  if (!list || !contacts) return null
  return (
    <StyledContact>
      <Headline>{translation.dashboard.sharedComponents.contactList.headline}</Headline>
      {Array.isArray(list.headers) && (
        <ListWrapper>
          <List>
            <Headers>
              <tr>
                <HeaderItem key={getUUID()}>#</HeaderItem>
                {list.headers.map((h) => (
                  <HeaderItem key={getUUID()}>{h.label}</HeaderItem>
                ))}
              </tr>
            </Headers>
            <Contacts>
              {Array.isArray(contacts) &&
                contacts.map((contact, i) => <Item key={contact.uuid} index={i} headers={list.headers} contact={contact} />)}
              <tr>
                <td>{getNextPageIndex() ? <LoadMore trigger={fetchContacts} /> : null}</td>
              </tr>
            </Contacts>
          </List>
        </ListWrapper>
      )}
    </StyledContact>
  )
}

export default Contact
