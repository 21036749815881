import styled from 'styled-components'
import { Form } from '@think-internet/ui-components'

export const StyledSignUp = styled.div`
  padding: 150px 0;
`

export const CustomForm = styled(Form)`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 15px;
  }
`

export const SwitchToLogin = styled.a`
  text-align: center;
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const SingleLine = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Separator = styled.div`
  width: 100%;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
`

export const Subline = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
`

export const SuccessMessage = styled.div`
  margin: 25px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
`
