import { StyledSenders } from './Senders.Styled'
import List, { MODES } from '../../SharedComponents/List/Sender/Sender'
import Create from './Create/Create'

const Senders = () => {
  return (
    <StyledSenders>
      <Create />
      <List mode={MODES.EDIT} />
    </StyledSenders>
  )
}

export default Senders
