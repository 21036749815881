import styled from 'styled-components'

export const StyledNewsletter = styled.div`
  margin-top: 50px;
`

export const Headline = styled.div`
  font-family: 'bold';
`

export const Items = styled.div``

export const OpenNewsletter = styled.a`
  display: block;
  padding: 10px 0px;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
  text-decoration: none;
  &:first-child {
    border-top: none;
  }
`
