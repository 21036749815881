import styled from 'styled-components'

export const StyledActivateAccount = styled.div`
  padding: 150px 0;
`

export const Message = styled.div`
  margin: 25px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
`

export const SwitchToLogin = styled.a`
  text-align: center;
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
