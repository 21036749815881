import { StyledSetMailHeader } from './SetMailHeader.Styled'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Dropdown } from '@think-internet/ui-components'
import { update } from '../../../../../redux/action/crud'

const SetMailHeader = () => {
  const headers = useSelector((s) => s[props.CONTACT_LIST_HEADER])
  const contactList = useSelector((s) => s[props.CONTACT_LIST])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()

  const getHeaderItems = () => {
    if (!headers) return []
    return headers.map((h) => ({ value: h.label, key: h.uuid }))
  }

  const set = (key) => {
    const updateObject = { ...contactList, mailAttributeHeaderUUID: key }
    dispatch(update(props.CONTACT_LIST, updateObject))
  }

  if (!Array.isArray(headers) || !contactList) return <></>
  return (
    <StyledSetMailHeader>
      <Dropdown
        label={translation.dashboard.user.sandbox.addContacts.setMailHeader.title}
        emptySelectionLabel={translation.UI.dropdown.emptySelection}
        items={getHeaderItems()}
        value={contactList.mailAttributeHeaderUUID}
        onChange={set}
      />
    </StyledSetMailHeader>
  )
}

export default SetMailHeader
