import * as operation from '../operation'

//actions
export const list = (featureName, query = {}, pageIndex, replace = undefined, reverseOrder = false) => ({
  type: operation.LIST,
  payload: {
    query,
    pageIndex: Number.isInteger(pageIndex) ? pageIndex : 0,
    featureName,
    replace: replace === undefined ? !Number.isInteger(pageIndex) : replace,
    reverseOrder,
  },
})

export const get = (featureName, query = {}, replace = true, reverseOrder = false) => ({
  type: operation.GET,
  payload: {
    query,
    featureName,
    replace,
    reverseOrder,
  },
})

export const create = (featureName, query = {}, replace = true, reverseOrder = false) => ({
  type: operation.CREATE,
  payload: { query, featureName, replace, reverseOrder },
})

export const update = (featureName, query = {}, replace = true, reverseOrder = false) => ({
  type: operation.UPDATE,
  payload: { query, featureName, replace, reverseOrder },
})

export const upsert = (featureName, query = {}, replace = true, reverseOrder = false) => ({
  type: operation.UPSERT,
  payload: { query, featureName, replace, reverseOrder },
})

export const multiUpsert = (featureName, query = {}, replace = true) => ({
  type: operation.MULTI_UPSERT,
  payload: { query, featureName, replace },
})

export const remove = (featureName, query = {}) => ({
  type: operation.DELETE,
  payload: { query, featureName },
})
