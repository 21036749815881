import styled from 'styled-components'

export const StyledNewsletterView = styled.div`
  padding: 150px 0;
`

export const Boxes = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`

export const CTAWrapper = styled.div``
