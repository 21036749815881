import { StyledActivateAccount, Message, SwitchToLogin } from './ActivateAccount.Styled'
import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import props from '../../redux/props'
import Headline from '../SubComponents/Headline/Headline'
import useFunctional from '../../interface/useFunctional'
import routes from '../../redux/routes'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const ActivateAccount = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const { accountUUID } = useParams()
  const activateAccount = useFunctional(routes.activate_account)
  const [success, setSuccess] = useState(null)

  useEffect(() => {
    ;(async () => {
      const response = await activateAccount({ accountUUID })
      setSuccess(!!response)
    })()
  })

  return (
    <Layout authRequired={false}>
      <StyledActivateAccount>
        <Container>
          <Headline text={translation.signUp.activation.headline} align="center" />
          {success === true && (
            <>
              <Message>{translation.signUp.activation.success}</Message>
              <SwitchToLogin href="/login">{translation.signUp.activation.switchToLogin}</SwitchToLogin>
            </>
          )}
          {success === false && <Message>{translation.signUp.activation.error}</Message>}
        </Container>
      </StyledActivateAccount>
    </Layout>
  )
}

export default ActivateAccount
