import { Button, Container } from '@think-internet/ui-components'
import Layout from '../../../Layout/Layout'
import { contactModes, levels } from '../../../../enum'
import { StyledListView } from './ListView.Styled'
import props from '../../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import Headline from '../../../SubComponents/Headline/Headline'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { get } from '../../../../interface/crud'
import ContactList from '../../SharedComponents/List/Contact/Contact'
import { setLocal } from '../../../../redux/action/local'

const ListView = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const contactList = useSelector((s) => s[props.CONTACT_LIST])
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const getList = async () => {
      const list = await get(token, props.CONTACT_LIST, { uuid })
      if (list) {
        dispatch(setLocal(props.CONTACT_LIST, list))
      }
    }
    if (!contactList || contactList.uuid !== uuid) {
      getList()
    }
  }, [token, uuid, contactList, dispatch])

  const navigateToSandbox = (uuid) => () => navigate(`/dashboard/sandbox/${uuid}`)

  return (
    <Layout requiredLevel={[levels.MANAGER, levels.USER]}>
      {contactList && (
        <StyledListView>
          <Headline text={contactList.name} align="center" />
          <Container>
            <Button onClick={navigateToSandbox(uuid)} text={translation.dashboard.user.listView.goToSandbox} />
            <ContactList contactListUUID={uuid} mode={contactModes.PRODUCTION} />
          </Container>
        </StyledListView>
      )}
    </Layout>
  )
}

export default ListView
