import { Textarea } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useCRUD from '../../../../../interface/useCRUD'
import props from '../../../../../redux/props'
import { StyledTemplate, Split, InputWrapper, VariablesWrapper } from './Template.Styled'

const Template = ({ contactListUUID, value = '', changeHander = null }) => {
  const getContactList = useCRUD(props.CONTACT_LIST).get
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [contactList, setContactList] = useState(null)

  useEffect(() => {
    const fetchContactList = async () => {
      const response = await getContactList({ uuid: contactListUUID })
      if (response) {
        setContactList(response)
      }
    }
    if (token && contactListUUID && (!contactList || contactList.uuid !== contactListUUID)) {
      fetchContactList()
    }
  }, [contactList, token, contactListUUID, getContactList])

  if (!contactListUUID || !contactList) return <></>
  return (
    <StyledTemplate>
      <Split>
        <InputWrapper>
          <Textarea
            rows={10}
            value={value}
            onChange={changeHander}
            placeholder={translation.dashboard.user.newsletterView.template.placeholder}
            label={translation.dashboard.user.newsletterView.template.label}
          />
        </InputWrapper>
        <VariablesWrapper>
          {contactList.headers.map((h) => (
            <div key={h.label}>{h.label}</div>
          ))}
        </VariablesWrapper>
      </Split>
    </StyledTemplate>
  )
}

export default Template
