import { useSelector } from 'react-redux'
import { count, get, list, create, update, remove, upsert, multiUpsert } from './crud'
import * as operations from '../redux/operation'
import customToast, { TYPE } from '../toast'

const useCRUD = (featureEnum) => {
  const token = useSelector((s) => s.TOKEN)

  const validate = (response, error, success) => {
    if (!error && !success) return response

    const isValid = () => {
      return !!response
    }

    if (success) {
      if (typeof success === 'string') {
        if (isValid()) {
          customToast(TYPE.SUCCESS, success)
          return response
        }
      } else if (typeof success === 'function') {
        const message = success(response)
        if (message) {
          customToast(TYPE.SUCCESS, message)
          return response
        }
      }
    }

    if (error) {
      if (typeof error === 'string') {
        if (!isValid()) {
          customToast(TYPE.ERROR, error)
          return false
        }
      } else if (typeof error === 'function') {
        const message = error(response)
        if (message) {
          customToast(TYPE.ERROR, message)
          return false
        }
      }
    }
    return response
  }

  const caller =
    (crudFunction, ...props) =>
    async () => {
      const result = await crudFunction(...props)
      return result
    }

  const init = (operation) => async (payload, error, success, pageIndex, matchProperties, query) => {
    const mapping = {
      [operations.COUNT]: caller(count, token, featureEnum, payload),
      [operations.GET]: caller(get, token, featureEnum, payload),
      [operations.LIST]: caller(list, token, featureEnum, payload, pageIndex),
      [operations.CREATE]: caller(create, token, featureEnum, payload),
      [operations.UPDATE]: caller(update, token, featureEnum, payload),
      [operations.DELETE]: caller(remove, token, featureEnum, payload),
      [operations.UPSERT]: caller(upsert, token, featureEnum, payload, matchProperties, query),
      [operations.MULTI_UPSERT]: caller(multiUpsert, token, featureEnum, payload, matchProperties, query),
    }

    const response = await mapping[operation]()
    const validatedResponse = validate(response, error, success)
    return validatedResponse
  }

  return {
    count: init(operations.COUNT),
    get: init(operations.GET),
    list: init(operations.LIST),
    create: init(operations.CREATE),
    update: init(operations.UPDATE),
    remove: init(operations.DELETE),
    upsert: init(operations.UPSERT),
    multiUpsert: init(operations.MULTI_UPSERT),
  }
}

export default useCRUD
