import { DragAndDrop } from '@think-internet/ui-components'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledAddContacts, Processing, Loading, Label } from './AddContacts.Styled'
import useFunctional from '../../../../../interface/useFunctional'
import routes from '../../../../../redux/routes'
import { useState } from 'react'
import { setLocal } from '../../../../../redux/action/local'

const AddContacts = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const contactList = useSelector((s) => s[props.CONTACT_LIST])
  const importContactsToSandbox = useFunctional(routes.import_contacts_to_sandbox)
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()

  const processFile = async (files) => {
    if (Array.isArray(files) && files.length === 1) {
      const file = files[0]
      setProcessing(true)
      const headerObjects = await importContactsToSandbox(
        { contactListUUID: contactList.uuid },
        translation.dashboard.user.sandbox.addContacts.error.import,
        translation.dashboard.user.sandbox.addContacts.success.import,
        file,
      )
      setProcessing(false)
      if (Array.isArray(headerObjects)) {
        dispatch(setLocal(props.CONTACT_LIST_HEADER, headerObjects))
      }
    }
  }
  if (!contactList) return <></>
  return (
    <StyledAddContacts>
      {processing && (
        <Processing>
          <Loading />
          <Label>{translation.dashboard.user.sandbox.addContacts.processingLabel}</Label>
        </Processing>
      )}
      {!processing && (
        <DragAndDrop
          title={translation.dashboard.user.sandbox.addContacts.dragAndDrop.title}
          hint={translation.dashboard.user.sandbox.addContacts.dragAndDrop.hint}
          onFileCallback={processFile}
          maxFileSizeInBytes={10000000}
          maxFileSizeExceededToast={translation.dashboard.user.sandbox.addContacts.dragAndDrop.maxFileSizeExceededToast}
          multiple={false}
          allowedFileExtensions={['csv']}
          invalidFileExtensionToast={translation.dashboard.user.sandbox.addContacts.dragAndDrop.invalidFileExtensionToast}
        />
      )}
    </StyledAddContacts>
  )
}

export default AddContacts
