import styled from 'styled-components'

export const StyledItem = styled.tr`
  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.color.background.white};
  }
`

export const Prop = styled.td`
  padding: 10px 10px;
`
