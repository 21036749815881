import { StyledIdentities } from './Identities.Styled'
import List from '../../SharedComponents/List/Identity/Identity'
import Create from './Create/Create'

const Identities = () => {
  return (
    <StyledIdentities>
      <Create />
      <List />
    </StyledIdentities>
  )
}

export default Identities
