import { StyledSignUp, CustomForm, SwitchToLogin, SingleLine, Separator, Subline, SuccessMessage } from './SignUp.Styled'
import { Container, Button, Input } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import toast, { TYPE } from '../../toast'
import props from '../../redux/props'
import Headline from '../SubComponents/Headline/Headline'
import useFunctional from '../../interface/useFunctional'
import routes from '../../redux/routes'

const SignUp = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState({})
  const signUp = useFunctional(routes.sign_up)
  const [success, setSuccess] = useState(false)

  const submit = async () => {
    const response = await signUp(data)
    if (response) {
      setSuccess(true)
    } else {
      toast(TYPE.ERROR, translation.signUp.error)
    }
  }

  const setProp = (prop) => (value) => {
    setData({ ...data, [prop]: value })
  }

  return (
    <Layout authRequired={false}>
      <StyledSignUp>
        <Container>
          <Headline text={translation.signUp.headline} align="center" />
          {success && <SuccessMessage>{translation.signUp.successMessage}</SuccessMessage>}
          {!success && (
            <>
              <CustomForm onSubmit={submit}>
                <Subline>{translation.signUp.personalDetail}</Subline>
                <SingleLine>
                  <Input placeholder={translation.generic.firstname} onChange={setProp('firstname')} value={data.firstname} required={true} />
                  <Input placeholder={translation.generic.lastname} onChange={setProp('lastname')} value={data.lastname} required={true} />
                </SingleLine>
                <Input placeholder={translation.generic.mail} onChange={setProp('mail')} value={data.mail} required={true} type="email" />
                <Input
                  placeholder={translation.generic.password}
                  onChange={setProp('password')}
                  value={data.password}
                  required={true}
                  type="password"
                />
                <Separator />
                <Subline>{translation.signUp.companyDetail}</Subline>
                <SingleLine>
                  <Input placeholder={translation.generic.company.name} onChange={setProp('companyName')} value={data.companyName} required={true} />
                  <Input
                    placeholder={translation.generic.company.taxNumber}
                    onChange={setProp('companyTaxNumber')}
                    value={data.companyTaxNumber}
                    required={true}
                  />
                </SingleLine>
                <SingleLine>
                  <Input
                    placeholder={translation.generic.company.street}
                    onChange={setProp('companyStreet')}
                    value={data.companyStreet}
                    required={true}
                  />
                  <Input
                    placeholder={translation.generic.company.postcode}
                    onChange={setProp('companyPostcode')}
                    value={data.companyPostcode}
                    required={true}
                  />
                  <Input placeholder={translation.generic.company.city} onChange={setProp('companyCity')} value={data.companyCity} required={true} />
                </SingleLine>
                <Input placeholder={translation.generic.company.phone} onChange={setProp('companyPhone')} value={data.companyPhone} required={true} />
                <Input
                  placeholder={translation.generic.company.website}
                  onChange={setProp('companyWebsite')}
                  value={data.companyWebsite}
                  required={true}
                />
                <Button type="submit" text={translation.signUp.cta} />
              </CustomForm>
              <SwitchToLogin href="/login">{translation.signUp.switchToLogin}</SwitchToLogin>
            </>
          )}
        </Container>
      </StyledSignUp>
    </Layout>
  )
}

export default SignUp
