import { Button } from '@think-internet/ui-components'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import useIsVisible from '../../../../../SubComponents/IsVisible/useIsVisible'

const LoadMore = ({ trigger }) => {
  const ref = useRef()
  const isVisible = useIsVisible(ref)
  const translation = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (isVisible) trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <div ref={ref}>
      <Button text={translation.generic.loadMore} onClick={trigger} />
    </div>
  )
}

export default LoadMore
