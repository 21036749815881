import { Button, Dropdown, Input } from '@think-internet/ui-components'
import { StyledCreate } from './Create.Styled'
import useCRUD from '../../../../../interface/useCRUD'
import { setLocal } from '../../../../../redux/action/local'
import toast, { TYPE } from '../../../../../toast'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { useEffect, useState } from 'react'
import { list } from '../../../../../redux/action/crud'

const Create = () => {
  const senders = useSelector((s) => s[props.SENDER])
  const identities = useSelector((s) => s[props.IDENTITY])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const createSender = useCRUD(props.SENDER).create
  const dispatch = useDispatch()
  const [identityUUID, setIdentityUUID] = useState('')
  const [mailPrefix, setMailPrefix] = useState('')

  useEffect(() => {
    if (!Array.isArray(identities)) {
      dispatch(list(props.IDENTITY))
    }
  }, [identities, dispatch])

  const create = async () => {
    const response = await createSender({ identityUUID, mailPrefix })
    if (response) {
      dispatch(setLocal(props.SENDER, [...senders, response]))
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.account.senders.create.error)
    }
  }

  const identityItems = () => identities.map((i) => ({ value: i.identity, key: i.uuid }))

  if (!Array.isArray(identities)) return <></>
  return (
    <StyledCreate>
      <Input
        value={mailPrefix}
        onChange={setMailPrefix}
        label={translation.dashboard.user.account.senders.create.input.label}
        placeholder={translation.dashboard.user.account.senders.create.input.placeholder}
      />
      <Dropdown
        label={translation.dashboard.user.account.senders.create.dropdown.label}
        emptySelectionLabel={translation.UI.dropdown.emptySelection}
        items={identityItems()}
        value={identityUUID}
        onChange={setIdentityUUID}
      />
      <Button onClick={create} text={translation.dashboard.user.account.senders.create.cta} />
    </StyledCreate>
  )
}

export default Create
