import styled from 'styled-components'

export const StyledIdentity = styled.div`
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
  margin-top: 15px;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
  &:first-child {
    border-top: none;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Name = styled.div`
  font-family: 'bold';
`

export const Prop = styled.div`
  display: flex;
  gap: 10px;
`

export const Key = styled.div`
  font-family: 'bold';
`
export const Value = styled.div``

export const CTASection = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`
