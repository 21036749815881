import { Button, Input } from '@think-internet/ui-components'
import { StyledCreate } from './Create.Styled'
import useCRUD from '../../../../../interface/useCRUD'
import { setLocal } from '../../../../../redux/action/local'
import toast, { TYPE } from '../../../../../toast'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { useState } from 'react'

const Create = () => {
  const identities = useSelector((s) => s[props.IDENTITY])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const createIdentity = useCRUD(props.IDENTITY).create
  const dispatch = useDispatch()
  const [domain, setDomain] = useState('')

  const create = async () => {
    const response = await createIdentity({ identity: domain })
    if (response) {
      dispatch(setLocal(props.IDENTITY, [...identities, response]))
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.account.identities.create.error)
    }
  }

  return (
    <StyledCreate>
      <Input
        value={domain}
        onChange={setDomain}
        label={translation.dashboard.user.account.identities.create.input.label}
        placeholder={translation.dashboard.user.account.identities.create.input.placeholder}
      />
      <Button onClick={create} text={translation.dashboard.user.account.identities.create.cta} />
    </StyledCreate>
  )
}

export default Create
