import styled from 'styled-components'

export const StyledSandbox = styled.div`
  padding: 150px 0;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: 'bold';
`

export const Hint = styled.div`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.font.size.sm};
`
