import { useState } from 'react'
import { StyledBox, Title, Body } from './Box.Styled'

const Box = ({ title, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <StyledBox>
      <Title onClick={() => setOpen(!open)}>{title}</Title>
      {open && <Body>{children}</Body>}
    </StyledBox>
  )
}

export default Box
