//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  nav: {
    user: {
      items: [
        { text: 'Newsletter', href: 'dashboard/newsletters' },
        { text: 'Listen', href: 'dashboard/lists' },
      ],
    },
    manager: {
      items: [
        { text: 'Newsletter', href: 'dashboard/newsletters' },
        { text: 'Listen', href: 'dashboard/lists' },
        { text: 'Account', href: 'dashboard/account' },
        { text: 'Benutzer', href: 'dashboard/users' },
      ],
    },
    logout: 'Logout',
  },

  dashboard: {
    logoutCTA: 'Ausloggen',
    loginCTA: 'Einloggen',
    user: {
      account: {
        headline: 'Account',
        identities: {
          title: 'Identitäten',
          create: {
            cta: 'Neue Identität hinzufügen',
            input: {
              label: 'Domain/E-Mail Adresse',
              placeholder: 'z.B. think-internet.de oder office@think-internet.de',
            },
            error: 'Diese Domain/E-Mail Adresse ist bereits in Ihren Identitäten vorhanden.',
          },
        },
        senders: {
          title: 'Absender',
          create: {
            cta: 'Neuer Absender hinzufügen',
            input: {
              label: 'E-Mail Präfix',
              placeholder: 'z.B. office',
            },
            dropdown: {
              label: 'Identität wählen',
            },
            error: 'Dieser Absender existiert bereits.',
          },
        },
      },
      users: {
        headline: 'Benutzer',
        create: {
          headline: 'Neuen Benutzer anlegen',
          success: {
            submit: 'Benutzer erfolgreich angelegt.',
          },
          error: {
            submit: 'Benutzer konnte nicht angelegt werden.',
          },
        },
        list: {
          headline: 'Bestehende Nutzer',
        },
        update: {
          level: {
            label: 'Berechtigungsstufe',
          },
          success: {
            save: 'Einstellungen gespeichert.',
          },
          error: {
            save: 'Einstellungen konnten nicht gespeichert werden.',
          },
        },
      },
      contactLists: {
        headline: 'Listen',
        create: {
          headline: 'Neue Liste anlegen',
          success: {
            submit: 'Liste erfolgreich angelegt.',
          },
          error: {
            submit: 'Liste konnte nicht angelegt werden.',
          },
        },
        update: {
          success: {
            save: 'Einstellungen gespeichert.',
          },
          error: {
            save: 'Einstellungen konnten nicht gespeichert werden.',
          },
        },
      },
      newsletters: {
        headline: 'Newsletters',
        create: {
          headline: 'Neuen Newsletter anlegen',
          success: {
            submit: 'Newsletter erfolgreich angelegt.',
          },
          error: {
            submit: 'Newsletter konnte nicht angelegt werden.',
          },
        },
        update: {
          success: {
            save: 'Einstellungen gespeichert.',
          },
          error: {
            save: 'Einstellungen konnten nicht gespeichert werden.',
          },
        },
      },
      listView: {
        goToSandbox: 'Kontakte hinzufügen',
        moveToProduction: 'Kontakte übernehmen',
      },
      newsletterView: {
        sendTestCTA: 'Test-Newsletter versenden',
        sendProductionCTA: 'Newsletter versenden',
        sender: {
          label: 'Absender',
          placeholder: 'Absender auswählen',
        },
        contactList: {
          label: 'Kontaktliste',
          placeholder: 'Kontaktliste auswählen',
        },
        subject: {
          label: 'Betreff',
          placeholder: 'Betreff eingeben',
        },
        template: {
          label: 'Inhalt',
          placeholder: 'HTML-Template eingeben',
        },
        success: {
          save: 'Newsletter gespeichert.',
          testValidity: 'Template ist gültig.',
          sendTest: 'Testmail versendet.',
          sendProduction: 'Newsletter versendet.',
        },
        error: {
          testValidity: 'Template ist ungültig.',
          sendProduction: 'Newsletter konnte nicht versendet werden.',
          sendTest: 'Testmail konnte nicht versendet werden.',
          save: 'Newsletter konnte nicht gespeichert werden.',
          fetching: 'Newsletter konnte nicht geladen werden.',
        },
      },
      sandbox: {
        headline: 'Kontakt - Sandbox',
        hint: 'Ihre Kontakte werden zuerst im Sandbox-Modus importiert, damit Sie ggf. Änderungen vornehmen können, sobald Sie entscheiden, dass Ihre Kontakte final in Ihre Kontaktliste aufgenommen werden können, klicken Sie "Kontakte übernehmen".',
        error: {
          moveToProduction: 'Ihre Kontakte konnten nicht übernommen werden.',
        },
        success: {
          moveToProduction: 'Ihre Kontakte wurden erfolgreich übernommen.',
        },
        addContacts: {
          processingLabel: 'Kontakte werden importiert und analysiert...',
          dragAndDrop: {
            title: 'Kontakte per Drag & Drop oder Klick hinzufügen',
            hint: 'Trennzeichen ";" | CSV-Datei bis zu 10 MB hochladen',
            maxFileSizeExceededToast: 'Die Datei ist zu groß. Maximal 10 MB erlaubt.',
            invalidFileExtensionToast: 'Die Dateiendung ist nicht erlaubt. Erlaubt ist CSV.',
          },
          setMailHeader: {
            title: 'E-Mail-Header Attribut wählen',
          },
          error: {
            import: 'Ihre Kontakte konnten nicht importiert werden.',
          },
          success: {
            import: 'Ihre Kontakte wurden erfolgreich importiert.',
          },
        },
      },
    },
    sharedComponents: {
      list: {
        contactList: {
          headline: 'Bestehende Listen',
        },
        newsletter: {
          headline: 'Bestehende Newsletter',
        },
        sender: {
          item: {
            status: 'Status: ',
            remove: 'Entfernen',
          },
        },
        identity: {
          item: {
            status: 'Status: ',
            token: 'DNS TXT-Record: ',
            remove: 'Entfernen',
            refresh: 'Aktualisieren',
          },
        },
      },
      contactList: {
        headline: 'Kontakte',
        error: {
          fetching: 'Ihre Kontakte konnten nicht geladen werden.',
        },
      },
    },
  },

  unsubscribe: {
    headline: 'Newsletter abbestellen',
    success: 'Sie haben den Newsletter erfolgreich abbestellt.',
    error: 'Ein technischer Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  gtc: {
    headline: 'AGB',
  },

  generic: {
    salutation: 'Anrede',
    title: 'Titel',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    address: 'Adresse',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Passwort',
    km: ' km',
    save: 'Speichern',
    view: 'Einsehen',
    remove: 'Löschen',
    message: 'Nachricht',
    description: 'Beschreibung',
    loadMore: 'Mehr laden',
    company: {
      name: 'Firmenname',
      taxNumber: 'USt-IdNr.',
      street: 'Straße',
      postcode: 'Postleitzahl',
      city: 'Stadt',
      phone: 'Telefon',
      website: 'Webseite',
    },
    prefixes: {
      position: 'Stelle: ',
      firstname: 'Vorname: ',
      lastname: 'Nachname: ',
      languageSkills: 'Sprachkenntnisse: ',
      nationality: 'Staatsangehörigkeit: ',
      address: 'Adresse: ',
      street: 'Straße: ',
      postcode: 'Postleitzahl: ',
      city: 'Stadt: ',
      contact: 'Kontakt: ',
      birthday: 'Geb.-Datum: ',
      age: 'Alter: ',
      maritalStatus: 'Familienstand: ',
      education: 'Ausbildung: ',
      professionalExperience: 'Berufserfahrung: ',
      availability: 'Verfügbarkeit: ',
      salaryExpectation: 'Gehaltsvorstellung: ',
      createdOn: 'Erstellt am: ',
    },

    level: {
      ADMIN: 'Administator',
      MANAGER: 'Manager',
      USER: 'Benutzer',
    },
  },

  genericInfo: {
    ceoName: 'Tim Haag',
    ceoTitle: 'Gründer und Geschäftsführer',
    ceoMail: 'office@think-internet.de',
    companyName: 'Think Internet Operations GmbH',
  },

  login: {
    headline: 'Login',
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
    switchToSignup: 'Noch kein Account? Hier registrieren.',
  },
  signUp: {
    headline: 'Registrieren',
    cta: 'Senden',
    error: 'Ihr Account konnte nicht registriert werden.',
    switchToLogin: 'Bereits registriert? Hier einloggen.',
    personalDetail: 'Persönliche Daten',
    companyDetail: 'Firmendaten',
    successMessage: 'Ihr Account wurde erfolgreich registriert. Sie erhalten in Kürze eine E-Mail mit einem Bestätigungslink.',
    activation: {
      headline: 'Account aktivieren',
      success: 'Ihr Account wurde erfolgreich aktiviert. Sie können sich nun einloggen.',
      error: 'Ihr Account konnte nicht aktiviert werden.',
      switchToLogin: 'Jetzt einloggen.',
    },
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
