import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledSender, Item, Content, Name, CTASection } from './Sender.Styled'
import useCRUD from '../../../../../interface/useCRUD'
import { setLocal } from '../../../../../redux/action/local'
import { useEffect } from 'react'
import { list } from '../../../../../redux/action/crud'
import { Button } from '@think-internet/ui-components'

export const MODES = {
  SELECTION: 'SELECTION',
  EDIT: 'EDIT',
}

const Sender = ({ clickedHandler = null, selectedSenderUUID = null, mode = MODES.SELECTION }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const senders = useSelector((s) => s[props.SENDER])
  const removeSender = useCRUD(props.SENDER).remove
  const dispatch = useDispatch()

  useEffect(() => {
    if (!senders) {
      dispatch(list(props.SENDER))
    }
  }, [senders, dispatch])

  const remove = async (uuid) => {
    const response = await removeSender({ uuid })
    if (!!response) {
      dispatch(
        setLocal(
          props.SENDER,
          senders.filter((i) => i.uuid !== uuid),
        ),
      )
    }
  }

  const select = (uuid) => () => {
    if (typeof clickedHandler === 'function' && mode === MODES.SELECTION) {
      clickedHandler(selectedSenderUUID === uuid ? null : uuid)
    }
  }

  if (!Array.isArray(senders)) return <></>
  return (
    <StyledSender>
      {senders.map((s) => (
        <Item key={s.uuid} onClick={select(s.uuid)} selected={selectedSenderUUID === s.uuid} mode={mode}>
          <Content>
            <Name>{s.mailPrefix ? `${s.mailPrefix}@${s.identity.identity}` : s.identity.identity}</Name>
          </Content>
          {mode === MODES.EDIT && (
            <CTASection>
              <Button
                className="danger"
                text={translation.dashboard.sharedComponents.list.sender.item.remove}
                variant="danger"
                onClick={() => remove(s.uuid)}
              />
            </CTASection>
          )}
        </Item>
      ))}
    </StyledSender>
  )
}

export default Sender
