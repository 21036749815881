import { StyledNewsletter, Headline, Items, OpenNewsletter } from './Newsletter.Styled'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { list } from '../../../../../redux/action/crud'

const List = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const newsletters = useSelector((s) => s[props.NEWSLETTER])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!newsletters) dispatch(list(props.NEWSLETTER))
  }, [newsletters, dispatch])

  if (!Array.isArray(newsletters)) return <></>
  return (
    <StyledNewsletter>
      <Headline>{translation.dashboard.sharedComponents.list.newsletter.headline}</Headline>
      <Items>
        {newsletters.map((l) => (
          <OpenNewsletter key={l.uuid} href={`/dashboard/newsletter/${l.uuid}`}>
            {l.name}
          </OpenNewsletter>
        ))}
      </Items>
    </StyledNewsletter>
  )
}

export default List
