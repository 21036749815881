const routes = {
  sign_up: 'sign_up',
  activate_account: 'activate_account',
  get_identity_status: 'get_identity_status',
  import_contacts_to_sandbox: 'import_contacts_to_sandbox',
  move_contacts_to_production: 'move_contacts_to_production',
  send_test_newsletter: 'send_test_newsletter',
  send_production_newsletter: 'send_production_newsletter',
  unsubscribe: 'unsubscribe',
  sns_mail_bounce_endpoint: 'sns_mail_bounce_endpoint',
  sns_mail_complaint_endpoint: 'sns_mail_complaint_endpoint',
  sns_mail_delivery_endpoint: 'sns_mail_delivery_endpoint',
}

export default routes
