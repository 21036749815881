import { StyledContactList, Items, Item } from './ContactList.Styled'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import props from '../../../../../redux/props'
import useCRUD from '../../../../../interface/useCRUD'

export const MODES = {
  SELECTION: 'SELECTION',
  DEFAULT: 'DEFAULT',
}

const ContactList = ({ clickedHandler = null, selectedContactListUUID = null, mode = MODES.DEFAULT }) => {
  const [lists, setLists] = useState(null)
  const list = useCRUD(props.CONTACT_LIST).list
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchLists = async () => {
      const lists = await list()
      if (Array.isArray(lists)) setLists(lists)
    }
    if (!lists) {
      fetchLists()
    }
  }, [lists, dispatch, list])

  const selected = (uuid) => () => {
    if (mode === MODES.DEFAULT) {
      window.location.href = `/dashboard/list/${uuid}`
    } else if (mode === MODES.SELECTION && clickedHandler) {
      clickedHandler(selectedContactListUUID === uuid ? null : uuid)
    }
  }

  if (!Array.isArray(lists)) return <></>
  return (
    <StyledContactList>
      <Items>
        {lists.map((l) => (
          <Item key={l.uuid} onClick={selected(l.uuid)} selected={selectedContactListUUID === l.uuid} mode={mode}>
            {l.name}
          </Item>
        ))}
      </Items>
    </StyledContactList>
  )
}

export default ContactList
