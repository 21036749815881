import Create from './Create/Create'
import List from '../SharedComponents/List/ContactList/ContactList'
import { Container } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { levels } from '../../../enum'
import { StyledContactList } from './ContactList.Styled'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Headline from '../../SubComponents/Headline/Headline'

const ContactList = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={[levels.MANAGER, levels.USER]}>
      <StyledContactList>
        <Headline text={translation.dashboard.user.contactLists.headline} align="center" />
        <Container>
          <Create />
          <List />
        </Container>
      </StyledContactList>
    </Layout>
  )
}

export default ContactList
