import { StyledLogin, CustomForm, SwitchToSignup } from './Login.Styled'
import { Container, Button, Input } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { login, resolveToken } from '../../interface/auth'
import toast, { TYPE } from '../../toast'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'
import Headline from '../SubComponents/Headline/Headline'

const Login = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const cookies = new Cookies()
  const navigate = useNavigate()

  const submit = async () => {
    const token = await login(identifier, password)
    if (token) {
      cookies.set('token', token, { path: '/' })
      const account = await resolveToken(token)
      if (!!account) {
        navigate('/dashboard/newsletters')
      } else {
        toast(TYPE.ERROR, translation.login.error)
      }
    } else {
      toast(TYPE.ERROR, translation.login.error)
    }
  }

  return (
    <Layout authRequired={false}>
      <StyledLogin>
        <Container>
          <Headline text={translation.login.headline} align="center" />
          <CustomForm onSubmit={submit}>
            <Input placeholder={translation.generic.mail} onChange={setIdentifier} value={identifier} required={true} />
            <Input placeholder={translation.generic.password} onChange={setPassword} value={password} required={true} type="password" />
            <Button type="submit" text={translation.login.cta} />
          </CustomForm>
          <SwitchToSignup href="/signup">{translation.login.switchToSignup}</SwitchToSignup>
        </Container>
      </StyledLogin>
    </Layout>
  )
}

export default Login
