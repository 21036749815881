import { useSelector } from 'react-redux'
import { StyledFooter, Flex, Text } from './Footer.Styled'
import { Container } from '@think-internet/ui-components'
import props from '../../../redux/props'

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const getCurrentFullYear = () => {
    const date = new Date()
    return date.getFullYear()
  }

  return (
    <StyledFooter>
      <Container>
        <Flex justify={'right'}>
          <Text>
            &copy;{` ${getCurrentFullYear()} `}
            {translation.genericInfo.companyName}
          </Text>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
