import { Button, Dropdown, Input } from '@think-internet/ui-components'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledUserContent, CTASection, Row } from './UserContent.Styled'
import { remove } from '../../../../../redux/action/crud'
import { useState } from 'react'
import { update } from '../../../../../interface/crud'
import toast, { TYPE } from '../../../../../toast'
import { levels } from '../../../../../enum'

const UserContent = ({ data: initData }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({ ...initData })
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])

  const removeHandler = async () => await dispatch(remove(props.USER, { uuid: data.uuid }))

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const save = async () => {
    const result = await update(token, props.USER, data)
    if (result) {
      setData(result)
      toast(TYPE.SUCCESS, translation.dashboard.user.users.update.success.save)
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.users.update.error.save)
    }
  }

  const levelItems = Object.keys(levels)
    .filter((l) => l !== 'ADMIN')
    .map((l) => ({ value: translation.generic.level[l], key: l }))

  return (
    <StyledUserContent>
      <Row>
        <Input value={data.firstname} onChange={updateProp('firstname')} placeholder={translation.generic.firstname} required />
        <Input value={data.lastname} onChange={updateProp('lastname')} placeholder={translation.generic.lastname} required />
      </Row>
      <Row>
        <Input value={data.mail} onChange={updateProp('mail')} placeholder={translation.generic.mail} type="email" required />
        <Input value={data.password} onChange={updateProp('password')} placeholder={translation.generic.password} type="password" />
      </Row>
      <Row>
        <Dropdown
          label={translation.dashboard.user.users.update.level.label}
          emptySelectionLabel={translation.UI.dropdown.emptySelection}
          items={levelItems}
          value={data.level}
          onChange={updateProp('level')}
        />
      </Row>
      <CTASection>
        <Button className="danger" text={translation.generic.remove} onClick={removeHandler} />
        <Button text={translation.generic.save} onClick={save} />
      </CTASection>
    </StyledUserContent>
  )
}

export default UserContent
