import styled from 'styled-components'

export const StyledContact = styled.div`
  margin-top: 15px;
`

export const Headline = styled.div`
  font-family: 'bold';
`

export const ListWrapper = styled.div`
  margin-top: 5px;
  height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
`

export const List = styled.table``

export const Headers = styled.thead`
  position: static;
`

export const HeaderItem = styled.th`
  text-align: left;
  font-family: 'bold';
  padding: 10px 10px;
`

export const Contacts = styled.tbody`
  overflow: auto;
`
